import { Container, Row } from "reactstrap";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postApi } from "../../services/axiosInstance";
import { API_PATH } from "../../services/apipath";
import { useForm } from "react-hook-form";
import Header from "components/Headers/Header";
import { toast } from "react-toastify";

import "../../assets/css/custom.css";

const Addstudent = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    // console.log(data, "Submitted data");
    setLoading(true);

    try {
      const res = await postApi(API_PATH.ADMIN.ADD_STUDENTS, data);
      if (res.status === 201) {
        toast.success("Student added successfully!");
        // console.log("Student added successfully", res.data);
        reset();
        // navigate("/admin/index");
      }
    } catch (error) {
      toast.error("Error adding student");
      // console.log(error, "Error adding student");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col d-flex justify-content-center p-lg-5 mb-lg-0 mb-3">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="shadow p-lg-5 p-3 w-50 formaddstudent"
            >
              <h1 className="">Create New Student</h1>

              <label>Full Name</label>
              <br />
              <input
                {...register("name", { required: "Full name is required" })}
                placeholder="Enter Full Name"
                className={`mb-3 w-100 addstudent inputfluid ${
                  errors.name ? "is-invalid" : ""
                }`}
                disabled={loading}
              />
              {errors.name && (
                <p className="text-danger">{errors.name.message}</p>
              )}
              <br />

              <button
                type="submit"
                className="open-modal-btn btndisable w-50"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Addstudent;
