export const API_PATH = {
  ADMIN: {
    LOGIN: "v2/admin/login",
    REMOVE_STUDENT: "v2/admin/removeStudent",
    ADD_STUDENTS: "v2/admin/addStudent",
    GET_ADMINSTUDENT: "v2/admin/getme",
    LOGINON_0FF_STUDENTS: "v2/admin/toggleLogin",
    DELETE_ALLSTUDENT: "v2/admin/deleteAllStudents",
    ENABLE_ALLLOGIN: "v2/admin/enableStudentsLogins",
    UPDATE_ADMINSTUDENT: "v2/admin/updateStudentCred",
    DISABLE_STUDENTLOGIN: "v2/admin/disableStudentsLogins",
    TOGGLE_STUDENT_LOGIN: "v2/admin/toggleLogin",
    ADD_SUBSCRIPTION: "v2/admin/addSubscription",





    // INSTITUTE_LIST: "superAdmin/getInstitutes",
    // INSTITUTE_COUNT: "superAdmin/getInstitutesCount",
    // INSTITUTE_SIGNUP: "superAdmin/instituteSignUp",
    // EDIT_INSTITUTE_DETAILS: 'superAdmin/editInstituteDetails',
    // LEADS: "superAdmin/getLeads",
    // UPDATE_LEAD: "superAdmin/editLead",
    // CHANGE_LEAD_STATUS: "superAdmin/changeLeadStatus",
    // ADD_INSTITUTE_REQUEST: 'superAdmin/instituteRequest',
    // REQUEST_LIST: "superAdmin/getRequests",
    // EDIT_REQUESTS: "superAdmin/editRequests",
    // DELETE_REQUEST: "superAdmin/deleteRequest",
    // ACCEPT_REQUEST: 'superAdmin/instituteSignUp'
  },
};
