import Index from "./views/Index";
import Plan from "./views/examples/Plan";
import Addstudent from "./views/examples/Addstudent";

import { FaTools } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { RiUserAddFill } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import Auth from "layouts/Auth";

var routes = [
  {
    path: "/index",
    name: <span style={{ color: "white", fontWeight: "bold", fontSize: "20px" }}>
      <FaTools style={{ marginRight: "4px" }} /> Manage
    </span>,
    component: <Index />,
    layout: "/admin",
    classNames: "text-white"
  },
  {
    path: "/addstudent",
    name: <span style={{ color: "white", fontWeight: "bold", fontSize: "20px" }}>
      <RiUserAddFill  style={{ marginRight: "4px" }} /> Add Student
    </span>,
    component: <Addstudent />,
    layout: "/admin",
  },

  {
    path: "/plan",
    name: <span style={{ color: "white", fontWeight: "bold", fontSize: "20px" }}>
      <FaRegCalendarAlt style={{ marginRight: "4px" }} /> Plan
    </span>,
    component: <Plan />,
    layout: "/admin",
  },
  
  {
    path: "/login",
    name: (
      <span style={{ color: "white", fontWeight: "bold", fontSize: "20px" }}>
        <FiLogOut style={{ marginRight: "4px" }} /> Logout
      </span>
    ),
    component: <Auth />,
    layout: "/auth",
  },
];
export default routes;
