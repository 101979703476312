import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import "../../assets/css/custom.css"

const Header = () => {
  const TotalStudents = localStorage.getItem('totalStudents')
  const paidStudentCount = localStorage.getItem('paidStudentCount')
  const unpaidStudentCount = localStorage.getItem('unpaidStudentCount') 
  return (
    <>
      <div className="header pb-8 pt-5 pt-md-7">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row className="d-lg-flex justify-content-between">
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0 ">
                  <CardBody className="py-4 boxone" >
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-dark mb-0"
                        >
                          Total Number of Students
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {TotalStudents}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody className="py-4 boxtwo">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-dark mb-0"
                        >
                          Active Students
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{paidStudentCount}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-line" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody className="py-4 boxthree">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-dark mb-0"
                        >
                          Unsubscribed Students
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{unpaidStudentCount}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
             
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
