import {
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header";

const Profile = () => {
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="bg-secondary shadow py-lg-4 py-4">
          <Col xl="8" className="order-sm-2 planon">
            <h3 className="px-3">Self-Managed Admin Panel : </h3>
            <ol>
              <li> Flexible Plan Options: Choose from <span className="planbold">Academic, Core, or Both</span> Plans </li>
              <li> Unlimited Student Management : Can add an <span className="planbold">Unlimited Number</span> of students</li>
              <li> Customizable Student Subscriptions</li>
              <li> Control Over Student Profiles : Can edit student details, including <span className="planbold">Names, Usernames, and Passwords</span>.</li>
              <li> One-Click Student Removal : Can  remove all students in a single click</li>
              <li> Access to Create & Control Student Logins</li>
            </ol>
          </Col>

          <Col xl="4" className="d-flex justify-content-center order-sm-1 plan">
            <div className="">
              <h3 className="">Plan Expiry Date :</h3>
              <button disabled className="open-modal-btn btndisable w-100">20/01/2025</button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
