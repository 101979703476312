import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
} from "reactstrap";

import "../assets/css/custom.css";
import React, { useState, useEffect } from "react";
import { IoSettings } from "react-icons/io5";
import Header from "components/Headers/Header";
import Switch from "react-switch";
import { useForm } from "react-hook-form";

import { deletetApi, getApi, postApi, putApi } from "../services/axiosInstance";
import { API_PATH } from "../services/apipath.js";
import { toast } from "react-toastify";

const Index = (props) => {

  // <---------------Add Subscription Api------------->
  const subscription = [
    { id: 1, name: "Subscription", minDays: 1, maxDays: 356 },
  ];

  const [modalStates, setModalStates] = useState(false);
  const [studentId, setStudentId] = useState();
  const [subscriptionDays, setSubscriptionDays] = useState("");
  const [plan, setPlans] = useState("");

  const openModal = (id) => { setModalStates(true); setStudentId(id); };
  const closeModal = () => { setModalStates(false); setStudentId(); };
  const saveSubscription = async (id) => {

    toast.success("Subscription Plan Added Successfully");

    try {
      const res = await postApi(API_PATH.ADMIN.ADD_SUBSCRIPTION, {
        id: id,
        numberOfDays: parseInt(subscriptionDays),
        studentPlan: parseInt(plan),
      });
      if (res.data) {
        closeModal();
        fetchStudents();
      }
    } catch (error) { }
  };

  // <---------------update user api--------------->
  // const [showModal, setShowModal] = useState(false);
  // const [name, setName] = useState('');
  // const [username, setUsername] = useState('');
  // const [password, setPassword] = useState('');

  // const handleOpenModal = () => setShowModal(true);
  // const handleCloseModal = () => setShowModal(false);

  // const handleSaveChanges = () => {
  //   // console.log('Name:', name);
  //   // console.log('Username:', username);
  //   // console.log('Password:', password);
  //   handleCloseModal();
  // };

  const disableAssignTests = true;
  const disableAttemptHistory = true;

  const [selectedOption, setSelectedOption] = useState("both");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // <==========UPDATE CRED STUDENT API===============>

  const [name, setName] = useState(); 
  const [username, setUsername] = useState([]);
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const handleOpenModal = (id, username) => {
    setCurrentId(id);
    setName(username);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSaveChanges = async (id) => {
    // // console.log("ID:", currentId, "Username:", username, "Password:", password);

    try {
      const res = await postApi(API_PATH.ADMIN.UPDATE_ADMINSTUDENT, {
        id: id,
        username: username,
        password: password,
      });

      // // console.log("API Response:", res);

      if (res.status === 200) {
        toast.success("Student details updated successfully");
        handleCloseModal();
        fetchStudents()
      } else {
        // console.log("Failed to update student details");
      }
    } catch (error) {
      // console.error("Error updating student details:", error);
    }
  };

  // <-----------------Login api--------------->
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    username: "",
    password: "",
  });

  const users = [{ username: "4318707694", password: "395920" }];

  function openLoginModal(user) {
    setLoginModalOpen(true);
    setCurrentUser(user);
  }

  function closeLoginModal() {
    setLoginModalOpen(false);
    setCurrentUser({ username: "", password: "" });
  }

  // <==========Delete Api===========>

  const [deleteId, setDeleteId] = useState(null);

  const deleteRequest = async (studentId) => {
    try {
      const payload = {
        studentId: studentId,
      };
      // console.log(payload);
      const res = await postApi(API_PATH.ADMIN.REMOVE_STUDENT, payload);
      if (res.status === 200) {
        // console.log("Student deleted successfully");
        fetchStudents();
      } else {
        // console.log("Failed to delete student");
      }
    } catch (error) {
      // console.error("Error deleting request:", error);
    }
  };
  

  // <====================Setting Modal=================>

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [enableStudentLogin, setEnableStudentLogin] = useState(true);
  const [enableResumeInMockTest, setEnableResumeInMockTest] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const options = [
    { id: 1, label: "Delete All Students Account", action: "deleteStudents" },
  ];

  const App = () => {
    const [enableOption, setEnableOption] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleToggle = (optionId) => {
      setSelectedOption(optionId);
      setIsModalOpen(true);
    };

    const handleConfirmDelete = () => {
      setEnableOption((prev) => ({
        ...prev,
        [selectedOption]: true,
      }));
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
      setSelectedOption(null);
    };
  };

  // <==========ENABLE_ALLLOGIN API===========>

  // API call handler for Enable Student Login
  const handleEnableStudentLoginChange = async (checked) => {
    setEnableStudentLogin(checked);
    // console.log(checked);

    try {
      const res = await postApi(
        checked
          ? API_PATH.ADMIN.ENABLE_ALLLOGIN
          : API_PATH.ADMIN.DISABLE_STUDENTLOGIN,
        {
          enable: checked,
        }
      );
      if (res.status === 200) {
        if (checked) {
          // console.log("Student login enabled successfully");
        } else {
          // console.log("Student login disabled successfully");
        }
      }
    } catch (error) {
      // console.log(error, "Error updating student login status");
    }
  };

  // <==========DELETE_ALLSTUDENT API===========>
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const toggleWarningModal = () => setIsWarningModalOpen(!isWarningModalOpen);

  const handleDeleteAllStudentsChange = async (checked) => {
    if (checked) {
      toggleWarningModal();
    } else {
      // console.log("Switch turned off, no deletion performed.");
      setEnableResumeInMockTest(checked);
    }
  };

  const confirmDeleteAllStudents = async () => {
    try {
      const res = await postApi(API_PATH.ADMIN.DELETE_ALLSTUDENT, {
        enable: true,
      });
      if (res.status === 200) {
        toast.success("All student accounts deleted successfully");
      }
    } catch (error) {
      // console.log(error, "Error deleting student accounts");
    } finally {
      setEnableResumeInMockTest(true);
      toggleWarningModal();
    }
  };
  // <==============GET STUDENT API===================>

  const [students, setStudents] = useState([]);
  const [cards, setCards] = useState([]);

  const fetchStudents = async () => {
    try {
      const res = await getApi(API_PATH.ADMIN.GET_ADMINSTUDENT);
      if (res.status === 200) {
        setCards(res.data)
        setStudents(res.data.studentsId || []);
        localStorage.setItem('totalStudents', res.data.totalStudents)
        localStorage.setItem('paidStudentCount', res.data.paidStudentCount)
        localStorage.setItem('unpaidStudentCount', res.data.unpaidStudentCount)
      }
    } catch (error) {
      // console.error("Error fetching students:", error);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  const updateStudentList = (newStudent) => {
    setStudents((prevStudents) => [...prevStudents, newStudent]);
  };

  // <========================Searchbar==================>
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredStudents = students.filter((student) =>
    student.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // <===================Togglebtn===================>

  const toggleLogin = async (id) => {
    try {
      // console.log(id);
      const res = await putApi(API_PATH.ADMIN.TOGGLE_STUDENT_LOGIN, {
        id: id,
      });
      if (res.status === 200) {
        // console.log(res.data);
        toast.success(
          `Student login ${res.data.disableLogin === true ? "disabled" : "enabled"
          }  successfully`
        );
        fetchStudents();
      } else {
        // console.log("Failed to delete student");
      }
    } catch (error) { }
  };

  return (
    <>
      <Header data={cards} />
      <Container className="mt--7" fluid>
        <Row className="">
          <Col className="mb-4 mb-xl-4" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center ">
                  <div className="col">
                    <h2 className="mb-0">Students List</h2>
                  </div>
                  <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                    <FormGroup className="mb-0 ">
                      <InputGroup className="input-group-alternative border border-dark">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search text-dark" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Search"
                          type="text"
                          className="text-dark custom-placeholder"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Form>

                  <a
                    style={{
                      fontSize: "22px",
                      marginRight: "15px",
                      cursor: "pointer",
                    }}
                    onClick={toggleModal}
                  >
                    <IoSettings />
                  </a>

                  {isModalOpen && (
                    <div style={overlayStyles}>
                      <div style={modalStyles}>
                        <h2 style={{ marginBottom: "20px", color: "dark" }}>Settings</h2>

                        {/* Enable Student Login */}
                        <div style={optionStyle}>
                          <span style={{ color: "dark" }}>Enable Student Login</span>
                          <Switch
                            onChange={(checked) => setEnableStudentLogin(checked)}
                            checked={enableStudentLogin}
                            onColor="#2DCE89"
                            offColor="#FF4C4C"
                          />
                        </div>

                        {/* Delete All Students Account */}
                        <div style={optionStyle}>
                          <span style={{ color: "dark" }}>Delete All Students Account</span>
                          <Switch
                            onChange={handleDeleteAllStudentsChange}
                            checked={enableResumeInMockTest}
                            onColor="#2DCE89"
                            offColor="#FF4C4C"
                          />
                        </div>
                        {/* Close button */}
                        <button onClick={toggleModal} style={closeButtonStyle}>Close</button>
                      </div>
                    </div>
                  )}

                  {/* Warning Modal for Deleting All Students */}
                  {isWarningModalOpen && (
                    <div style={overlayStyles}>
                      <div style={warningModalStyles}>
                        <h3 className="text-danger">Warning</h3>
                        <p className="text-dark">Are you sure you want to delete all student accounts? This action cannot be undone.</p>
                        <button className="confirm-button" onClick={confirmDeleteAllStudents}>Confirm</button>
                        <button className="cancel-button" onClick={toggleWarningModal}>Cancel</button>
                      </div>
                    </div>
                  )}
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col-3" style={{ fontSize: "13px" }}>
                      Student Name
                    </th>
                    <th scope="col-3" style={{ fontSize: "13px" }}>
                      Add Subscription
                    </th>
                    <th scope="col-3" style={{ fontSize: "13px" }}>
                      Login
                    </th>
                    <th
                      scope="col-3"
                      style={{ fontSize: "13px", textAlign: "end" }}
                    >
                      More Options
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {(searchQuery ? filteredStudents : students).map(
                    (student, index) => (
                      <tr
                        key={student._id}
                        className={
                          student.disableLogin === true ? "text-danger" : ""
                        }
                      >
                        {/* Student Name */}
                        <th scope="row" style={{ fontSize: "15px" }}>
                          {student.name}
                        </th>

                        {/* Subscription Column */}
                        <td>
                          <div key={plan.id}>
                            <button
                              disabled={student.isVip === true}
                              onClick={() => openModal(student._id)}
                              className={student.isVip === true ? "open-modal-btn btn-success" : "open-modal-btn"}
                            >
                              Add Subscription
                            </button>

                            {modalStates && (
                              <div className="modal-overlay">
                                <div className="modal-content">
                                  <h2 className="modal-title">
                                    Add Subscription
                                  </h2>
                                  <button
                                    onClick={() => closeModal()}
                                    className="close-modal-btn"
                                  >
                                    X
                                  </button>

                                  <div className="subscription-form">
                                    <div className="subscription-input">
                                      <input
                                        type="number"
                                        min={plan.minDays}
                                        max={plan.maxDays}
                                        value={subscriptionDays}
                                        onChange={(e) =>
                                          setSubscriptionDays(e.target.value)
                                        }
                                      />
                                      <span
                                        className="text-dark"
                                        style={{ color: "black !important" }}
                                      >
                                        Days (1 - 365)
                                      </span>
                                    </div>

                                    <br />

                                    <Form>
                                      <FormGroup tag="fieldset">
                                        <legend className="text-dark">
                                          Select a Plan
                                        </legend>
                                        <div className="radio-input-wrapper">
                                          <label className="label">
                                            <input
                                              value="0"
                                              name={`value-radio-${plan.id}`}
                                              id="value-2"
                                              className="radio-input"
                                              type="radio"
                                              onChange={(e) =>
                                                setPlans(e.target.value)
                                              }
                                            />
                                            <div className="radio-design ml-lg-4"></div>
                                            <div className="label-text ">
                                              Both
                                            </div>
                                          </label>
                                          <label className="label">
                                            <input
                                              value="1"
                                              name={`value-radio-${plan.id}`}
                                              id="value-3"
                                              className="radio-input"
                                              type="radio"
                                              onChange={(e) =>
                                                setPlans(e.target.value)
                                              }
                                            />
                                            <div className="radio-design"></div>
                                            <div className="label-text">
                                              Core
                                            </div>
                                          </label>
                                          <label className="label">
                                            <input
                                              value="2"
                                              name={`value-radio-${plan.id}`}
                                              id="value-4"
                                              className="radio-input"
                                              type="radio"
                                              onChange={(e) =>
                                                setPlans(e.target.value)
                                              }
                                            />
                                            <div className="radio-design"></div>
                                            <div className="label-text">
                                              Academic
                                            </div>
                                          </label>
                                        </div>
                                      </FormGroup>
                                    </Form>

                                    <button
                                      onClick={() =>
                                        saveSubscription(studentId)
                                      }
                                      className="save-btn"
                                    >
                                      Add Subscription
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>

                        {/* Login Button */}
                        <td>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => openLoginModal(student)}
                          >
                            Login
                          </Button>


                        </td>

                        {/* Edit and Delete Options */}
                        <td style={{ textAlign: "end" }}>
                          <i
                            className="fas fa-pen text-success mr-3 ml-1"
                            style={{ fontSize: "18px", cursor: "pointer" }}
                            onClick={() => handleOpenModal(student._id, student)}
                          />

                          {/* Modal */}
                          {showModal && (
                            <div
                              className="modal d-block"
                              tabIndex="-1"
                              role="dialog"
                            >
                              <div className="modal-dialog" role="document">
                                <div className="modal-overlay">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h2 className="modal-title">
                                        Edit Details
                                      </h2>
                                      <button
                                        type="button"
                                        className="close"
                                        aria-label="Close"
                                        onClick={handleCloseModal}
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body py-0">
                                      <div
                                        className="form-group"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label htmlFor="username">Username</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="username"
                                          // value={username}
                                          defaultValue={name.username}
                                          onChange={(e) =>
                                            setUsername(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div
                                        className="form-group"
                                        style={{ textAlign: "left" }}
                                      >
                                        <label htmlFor="password">Password</label>
                                        <input
                                          type="password"
                                          className="form-control"
                                          id="password"
                                          defaultValue={name.password}
                                          onChange={(e) =>
                                            setPassword(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="modal-footer pt-0">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={handleCloseModal}
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() =>
                                          handleSaveChanges(currentId)
                                        }
                                      >
                                        Save changes
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <Button
                            type="button"
                            onClick={() => deleteRequest(student._id)}
                            className="btnremove border-0"
                          >
                            <i
                              className="fas fa-trash-alt text-danger ml-2"
                              style={{ fontSize: "18px" }}
                            />
                          </Button>

                          <UncontrolledDropdown nav>
                            <DropdownToggle nav className="nav-link-icon">
                              <i
                                className="fas fa-ellipsis-v text-dark ml-2"
                                style={{ fontSize: "18px" }}
                              />
                            </DropdownToggle>

                            <DropdownMenu
                              aria-labelledby="navbar-default_dropdown_1"
                              className="dropdown-menu-arrow"
                              left
                            >
                              <DropdownItem
                                onClick={() => toggleLogin(student._id)}
                              >
                                {student.disableLogin === true
                                  ? "Enable Login"
                                  : "Disable Login"}
                              </DropdownItem>
                              <DropdownItem disabled={disableAssignTests}>
                                Assign Tests
                              </DropdownItem>
                              <DropdownItem disabled={disableAttemptHistory}>
                                Attempt History
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    )
                  )}

                  <Modal
                    isOpen={isLoginModalOpen}
                    toggle={closeLoginModal}
                    className="custom-modal"
                  >
                    <ModalBody
                      style={{ textAlign: "center", padding: "20px" }}>
                      <div className="modal-content-custom">
                        <p style={{ fontSize: "18px", color: "#333" }}>
                          Username: {currentUser.username}
                        </p>
                        <p style={{ fontSize: "18px", color: "#333" }}>
                          Password: {currentUser.password}
                        </p>
                      </div>
                      <Button color="success" onClick={closeLoginModal}>
                        OK
                      </Button>
                    </ModalBody>
                  </Modal>

                </tbody>


              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const overlayStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalStyles = {
  backgroundColor: "#EFEFF0",
  padding: "20px",
  borderRadius: "8px",
  width: "400px",
  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
};

const optionStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0",
};

const closeButtonStyle = {
  backgroundColor: "#ff4c4c",
  color: "white",
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  marginTop: "20px",
};

const warningModalStyles = {
  backgroundColor: '#EFEFF0',
  padding: '20px',
  color: 'white',
  textAlign: 'center',
};

export default Index;
